import { Component } from "react";
import { Container, Row, Spinner, Table, Button, Col } from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";
import { UserBackendAPI, IBackendAPI } from "../api/UserBackendAPI";
import { EmailSummary } from "../api/model/EmailSummary";
import { domainName } from "../constants";

interface EmailListState {
    emails?: EmailSummary[];
}

interface EmailListProps {
    user: string;
}

export class EmailList extends Component<RouteComponentProps<EmailListProps>, EmailListState> {
    private backendAPI: IBackendAPI;
    private apiKey: string;
    private user: string;

    constructor(props: any) {
        super(props);

        this.state = {};
        
        this.user = props.match.params.user;

        this.backendAPI = new UserBackendAPI();
    }

    componentDidMount() {
        this.backendAPI.getEmailList(this.user)
            .then((emailList) => this.setState({ emails: emailList }));
    }

    render() {
        if (!this.state.emails) {
            return <Container><Spinner animation='border' size='sm' variant='primary'/></Container>;
        }

        return <Container>
            <Row>
                <Col><h2 className="App-header">{this.user}@{domainName}</h2></Col>
            </Row>
            <Row>
                <Button href={'/user/' + this.user + '/email/new'}>New Email</Button>
            </Row>
            <Row>
                <hr/>
            </Row>
            <Row>
                <Table>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Subject</th>
                            <th>Direction</th>
                            <th>Open</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.emails.map(email => {
                                return (<tr>
                                    <td>{this.boldIfUnread(email, new Date(email.timestamp*1000).toLocaleString())}</td>
                                    <td>{this.boldIfUnread(email, email.subject)}</td>
                                    <td>{this.boldIfUnread(email, email.direction)}</td>
                                    <td><Button href={'/user/' + this.user + '/email/' + email.id} >{this.boldIfUnread(email, "Open")}</Button></td>
                                </tr>);
                            })
                        }
                    </tbody>
                </Table>
            </Row>
            
        </Container>;
    }

    boldIfUnread(email: EmailSummary, stringContents: string) {
        return email.read ? stringContents : <b>{stringContents}</b>;
    }

}