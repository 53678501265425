import Amplify from "aws-amplify";
import { AmplifyAuthenticator, AmplifySignOut } from "@aws-amplify/ui-react";

import './App.css';
import { FunctionComponent } from 'react';
import { Container, Row } from 'react-bootstrap';
import { EmailList } from './pages/EmailList';

import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import routes from './routes';
import { EmailDetail } from './pages/EmailDetail';
import NotFoundPage from './pages/error/NotFoundPage';
import { EmailMailboxList } from './pages/EmailMailboxList';
import { EmailWriter } from './pages/EmailWriter';

require('dotenv').config();

Amplify.configure({
    aws_cognito_region: process.env.REACT_APP_REGION,
    aws_user_pools_id: process.env.REACT_APP_COGNITO_POOLS_ID,
    aws_user_pools_web_client_id: process.env.REACT_APP_COGNITO_POOLS_CLIENT_ID,
    aws_cognito_identity_pool_id: process.env.REACT_APP_IDENTITY_POOL_ID,
    aws_mandatory_sign_in: "enable"
});

export const App: FunctionComponent = () => {
    return (
        <AmplifyAuthenticator>
            <Container className="App p-3" >
                <AmplifySignOut/>
                <Row className="App-body">
                    <BrowserRouter>
                        <Switch>
                            {routes.root !== "/" ? (
                                <Route exact path="/" render={() => <Redirect to={routes.root} />} />
                            ) : null}

                            <Route exact path={routes.root} component={EmailMailboxList}/>
                            <Route exact path={routes.mailboxList} component={EmailMailboxList}/>
                            <Route exact path={routes.emailListLogin} component={EmailList}/>
                            <Route exact path={routes.emailCreator} component={EmailWriter}/>
                            <Route exact path={routes.emailDetailLogin} component={EmailDetail}/>
                            <Route render={() => <NotFoundPage />} />
                        </Switch>
                    </BrowserRouter>
                </Row>
            </Container>
        </AmplifyAuthenticator>
    );
};