import { Component } from "react";
import { Container, Row, Spinner, Table } from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";
import { UserBackendAPI, IBackendAPI } from "../api/UserBackendAPI";

interface EmailMailboxListState {
    mailboxes?: string[];
}

export class EmailMailboxList extends Component<RouteComponentProps, EmailMailboxListState> {
    private backendAPI: IBackendAPI;

    constructor(props: any) {
        super(props);

        this.state = {};

        this.backendAPI = new UserBackendAPI();
    }

    componentDidMount() {
        this.backendAPI.getMailboxList()
            .then((mailboxes) => this.setState({ mailboxes }));
    }

    render() {
        if (!this.state.mailboxes) {
            return <Container><Spinner animation='border' size='sm' variant='primary'/></Container>;
        }

        return <Container>
            <Row>
                <Table>
                    <thead>
                        <tr>
                            <th>Mailbox</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.mailboxes.map(mailbox => {
                                return (<tr>
                                    <td><a href={"/user/" + mailbox + "/email"}>{mailbox}</a></td>
                                </tr>);
                            })
                        }
                    </tbody>
                </Table>
            </Row>
            
        </Container>;
    }

}