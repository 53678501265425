import { Component } from "react";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";
import { UserBackendAPI, IBackendAPI } from "../api/UserBackendAPI";
import DOMPurify from 'dompurify';
import { EmailData } from "../api/model/EmailData";

interface EmailDetailProps {
    user: string;
    id: string;
}

interface EmailDetailState {
    emailContents?: string;
    emailData?: EmailData;
    loading: boolean;
}

export class EmailDetail extends Component<RouteComponentProps<EmailDetailProps>, EmailDetailState> {
    readonly id: string;
    readonly user: string;

    private backendAPI: IBackendAPI;

    constructor(props: RouteComponentProps<EmailDetailProps>) {
        super(props);

        this.id = props.match.params.id;

        this.state = { loading: true };

        this.user = props.match.params.user;

        this.backendAPI = new UserBackendAPI();
    }

    componentDidMount() {
        this.backendAPI.getEmail(this.user, this.id)
            .then(email => this.setState({emailContents: email, loading: false}));
        this.backendAPI.getEmailData(this.user, this.id)
            .then(emailData => this.setState({emailData}));

        this.backendAPI.setEmailMetadata(this.user, this.id, { read: true });
    }

    render() {
        if (!this.state.emailContents || this.state.loading) {
            return <Container><Spinner animation='border' size='sm' variant='primary'/></Container>;
        }

        return <Container>
            <Col>
                <Row><h3>Id: {this.id}</h3></Row>
                <Row>
                    <Button onClick={this.onReplyClick.bind(this)}>Reply</Button>
                </Row>
                <Row>
                    <div className="card card-body" style={{textAlign: "left"}} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.state.emailContents) }} />
                </Row>
            </Col>
        </Container>;
    }

    onReplyClick() {
        this.setState({loading: false});


        if (!this.state.emailData) {
            this.backendAPI.getEmailData(this.user, this.id)
                .then(emailData => {
                    var replyToId = encodeURIComponent(emailData.emailMessageId);
                    var replyToEmails = encodeURIComponent(emailData.replyTo.join(","));
                    var subject = encodeURIComponent(emailData.subject.toUpperCase().includes("RE:") ? emailData.subject : "Re: " + emailData.subject.trimStart());

                    this.props.history.push('/user/' + this.user + '/email/new?replyTo=' +  replyToId + '&to=' + replyToEmails + '&subject=' + subject);
                });
        } else {
            var replyToId = encodeURIComponent(this.state.emailData.emailMessageId);
            var replyToEmails = encodeURIComponent(this.state.emailData.replyTo.join(","));
            var subject = encodeURIComponent(this.state.emailData.subject.toUpperCase().includes("RE:") ? this.state.emailData.subject : "Re: " + this.state.emailData.subject.trimStart());

            this.props.history.push('/user/' + this.user + '/email/new?replyTo=' +  replyToId + '&to=' + replyToEmails + '&subject=' + subject);
        }
    }
}