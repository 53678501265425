import { ChangeEvent, Component } from "react";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";
import { UserBackendAPI, IBackendAPI } from "../api/UserBackendAPI";

interface EmailWriterProps {
    user: string;
}

interface ReplyData {
    replyToId: string;
    to: string[];
    subject: string;
}

interface EmailWriterState {
    to: string[];
    subject: string;
    emailContents: string;
    replyTo?: ReplyData;

    sending: boolean;
}

export class EmailWriter extends Component<RouteComponentProps<EmailWriterProps>, EmailWriterState> {
    readonly user: string;
    private backendAPI: IBackendAPI;

    constructor(props: RouteComponentProps<EmailWriterProps>) {
        super(props);

        this.state = {
            to: [],
            subject: "",
            emailContents: "",
            sending: false
        };
        this.user = props.match.params.user;
        const query = new URLSearchParams(this.props.location.search);
        if (query.has("replyTo") && query.has("to") && query.has("subject")) {
            const replyTo = decodeURIComponent(query.get("replyTo")!);
            const to = decodeURIComponent(query.get("to")!).split(",");
            const subject = decodeURIComponent(query.get("subject")!);

            this.state = {...this.state, replyTo: {
                replyToId: replyTo,
                to,
                subject
            }}
        }
        this.backendAPI = new UserBackendAPI();
    }

    componentDidMount() {
    }

    render() {
        if (this.state.sending) {
            return <Container><Spinner animation='border' size='sm' variant='primary'/></Container>;
        }

        return <Container>
            <Col>
                <Row>
                    <Col xs="2"><h4><b>To:</b></h4></Col>
                    <Col><input type="text" className="form-control" disabled={this.state.replyTo != undefined} onChange={this.onToChange.bind(this)} value={this.state.replyTo ? this.state.replyTo.to.join("; ") : (this.state.to.length > 0 ? this.state.to[0] : "")}/></Col>
                </Row>
                <Row><hr/></Row>
                <Row>
                    <Col xs="2"><h4><b>Subject:</b></h4></Col>
                    <Col><input type="text" className="form-control" disabled={this.state.replyTo != undefined} onChange={this.onSubjectChange.bind(this)} value={this.state.replyTo ? this.state.replyTo.subject : (this.state.subject ? this.state.subject : "")}/></Col>
                </Row>
                <Row><hr/></Row>
                <Row>
                    <Col xs="2"><h4><b>Email Text:</b></h4></Col>
                    <Col><textarea rows={10} className="form-control" onChange={this.onTextAreaChange.bind(this)} value={this.state.emailContents ? this.state.emailContents : ""}/></Col>
                </Row>
                <Row><hr/></Row>
                <Row><Button onClick={this.onButton.bind(this)}>Send</Button></Row>
            </Col>
        </Container>;
    }

    onToChange(e: ChangeEvent<HTMLInputElement>) {
        this.setState({to: [e.target.value]});
    }

    onSubjectChange(e: ChangeEvent<HTMLInputElement>) {
        this.setState({subject: e.target.value});
    }
    
    onTextAreaChange(e: ChangeEvent<HTMLTextAreaElement>) {
        this.setState({emailContents: e.target.value});
    }

    onButton() {
        console.log(this.state.replyTo);
        if (this.state.replyTo == undefined && (this.state.to.length <= 0 || this.state.subject === "" || this.state.emailContents === "")) {
            console.log("Not sending. Invalid");
            return;
        }

        console.log("Sending an email");

        this.setState({
            sending: true
        });

        var to = this.state.replyTo ? this.state.replyTo.to : this.state.to;
        var subject = this.state.replyTo ? this.state.replyTo.subject : this.state.subject;

        this.backendAPI.sendEmail(this.user, to, subject, this.state.emailContents, this.state.replyTo?.replyToId)
            .then((success) => {
                console.log("email send success");

                this.setState({sending: false});

                this.props.history.goBack();
            })
            .catch((e) => {
                console.log("error", e);

                this.setState({sending: false});
            });

        this.setState({
            to: [],
            subject: "",
            emailContents: "",
            replyTo: undefined
        })
    }
}

const styles = {
    editor: {
      border: '1px solid gray',
      minHeight: '6em'
    }
};