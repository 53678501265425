import React from "react";
import { Col, Container, Row } from "react-bootstrap";

interface IProps {
    errorCode: number;
    title: string;
    text?: string;
}

export default function ErrorPage(props: IProps) {
    const { errorCode, title, text } = props;

    return (
        <Container>
            <Col>
                <Row>
                    <h1>{errorCode}</h1>
                </Row>

                <Row>
                    <h5>
                        {title}
                    </h5>
                </Row>

                {typeof text === "undefined" ? null : (
                    <Row>
                        <p>
                            {text}
                        </p>
                    </Row>
                )}
            </Col>
        </Container>
    );
}
