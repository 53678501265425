import axios from 'axios';
import { EmailData } from './model/EmailData';
import { EmailSummary } from './model/EmailSummary';
import { Auth } from 'aws-amplify';

export interface IBackendAPI {
    getEmailList(user: string): Promise<EmailSummary[]>;
    getEmail(user: string, id: string): Promise<string>;
    getEmailData(user: string, id: string): Promise<EmailData>;
    setEmailMetadata(user: string, id: string, metadata: {read?: boolean}): Promise<void>;
    getMailboxList(): Promise<string[]>;
    sendEmail(user: string, to: string[], subject: string, emailText: string, replyTo?: string): Promise<boolean>;
};

interface SendEmailRequest {
    To: string[];
    Cc: string[];
    Bcc: string[];
    Subject: string;
    Content: string;
    InReplyTo?: string;
}

interface ApiGatewayClient {
    invokeApi(pathParams: any, pathTemplate: any, method: any, additionalParams: any, body: any): Promise<any>;
}

export class UserBackendAPI implements IBackendAPI {
    private readonly _baseUrl = "https://grvll157z4.execute-api.eu-west-1.amazonaws.com/prod/api/v1/";

    constructor() {
    }

    public async getAuthenticatedHeaders(): Promise<{Authorization: string}> {
        await Auth.currentAuthenticatedUser();
        const creds = await Auth.currentSession();

        return { Authorization: creds.getIdToken().getJwtToken() };
    }

    public async getMailboxList(): Promise<string[]> {
        const headers = await this.getAuthenticatedHeaders();
        const apiResponse = await axios.get(this._baseUrl + `user`, {
            headers
        });

        if (apiResponse.status < 200 || apiResponse.status >=400) {
            throw new Error("Failure from API response. Status: " + apiResponse.status);
        }

        const mailboxes = convertKeys(apiResponse.data).userEmails as string[];

        return mailboxes;
    }

    public async getEmailList(user: string): Promise<EmailSummary[]> {
        const headers = await this.getAuthenticatedHeaders();
        const apiResponse = await axios.get(this._baseUrl + `user/${user}/email`, {
            headers
        });

        if (apiResponse.status < 200 || apiResponse.status >=400) {
            throw new Error("Failure from API response. Status: " + apiResponse.status);
        }

        const emails = convertKeys(apiResponse.data).emails as EmailSummary[];

        return emails;
    }

    public async getEmail(user: string, id: string): Promise<string> {
        const headers = await this.getAuthenticatedHeaders();
        const apiResponse = await axios.get(this._baseUrl + `user/${user}/email/${id}`, {
            headers,
            responseType: "text"
        });

        if (apiResponse.status < 200 || apiResponse.status >=400) {
            throw new Error("Failure from API response. Status: " + apiResponse.status);
        }

        const emailContents = apiResponse.data as string;

        return emailContents;
    }

    public async getEmailData(user: string, id: string): Promise<EmailData> {
        const headers = await this.getAuthenticatedHeaders();
        const apiResponse = await axios.get(this._baseUrl + `user/${user}/email/${id}/data`, {
            headers
        });

        if (apiResponse.status < 200 || apiResponse.status >=400) {
            throw new Error("Failure from API response. Status: " + apiResponse.status);
        }

        return convertKeys(apiResponse.data) as EmailData;
    }

    public async setEmailMetadata(user: string, id: string, metadata: {read?: boolean}) {
        const headers = await this.getAuthenticatedHeaders();
        await axios.put(this._baseUrl + `user/${user}/email/${id}/meta`,
            {
                read: metadata.read
            }
            , {
                headers
            }
        );
    }

    public async sendEmail(user: string, to: string[], subject: string, emailText: string, inReplyTo?: string): Promise<boolean> {
        const headers = await this.getAuthenticatedHeaders();
        const sendEmailRequestBody: SendEmailRequest = {
            To: to,
            Bcc: [],
            Cc: [],
            Subject: subject,
            Content: emailText,
            InReplyTo: inReplyTo
        };

        const apiResponse = await axios.post(this._baseUrl + `user/${user}/email`,
            sendEmailRequestBody,
            {
                headers,
                responseType: "text",
            }
        );

        if (apiResponse.status < 200 || apiResponse.status >=400) {
            throw new Error("Failure from API response. Status: " + apiResponse.status);
        }

        return true;
    }
}

function delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
}

function convertKeys(obj: any): any {
    //Temporary object for storing traverse results
    var tmpObj = {};
    //Process array objects
    if (Array.isArray(obj)) {
        var arr = [];
        //traverse through array content
        for (var i in obj) {
            if (obj[i] !== null && typeof (obj[i]) == "object") {
                arr.push(convertKeys(obj[i]));
            } else {
                arr.push(obj[i]);
            }
        }
        tmpObj = arr;
    }
    //IF not array, process other objects
    else {
        for (var i in obj) {
            var j = camelize(i);
            if (obj[i] !== null && typeof (obj[i]) == "object") {
                tmpObj[j] = convertKeys(obj[i]);
            } else {
                //If not array or object, assume that we have string, etc. value
                tmpObj[j] = obj[i];
            }
        }
    }
    return tmpObj;
}

function camelize(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function(match, index) {
      if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
      return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });
  }
  