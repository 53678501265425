import React from "react";
import ErrorPage from "./ErrorPage";

export default function NotFoundPage() {
    return (
        <ErrorPage
            errorCode={404}
            title="We couldn't find the page you were looking for"
            text="You may have mistyped the URL or the page may have moved"
        />
    );
}
